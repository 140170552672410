.ApiError .modal-header {
  font-size: 16px;
  font-weight: bold;
}

.ApiError .modal-body {
  font-size: 14px;
}

.ApiError .modal-footer {
  font-size: 10px;
  font-weight: 300;
}