
.DownloadModal .modal-lg {
  max-width: 910px;
}

.Download {
  text-align: center;
}

.Download .card-body {
  padding: 10px;
}

.DownloadModal .download-header h2 {
  font-size: 18px;
}

.DownloadModal .download-header.download-original.col-0 {
  display: block;
}

.DownloadModal .download-header.download-original.col-12 {
  display: none;
}

.Download .download-button {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  width: 445px;
  height: 276px;
}

.Download .download-button .spinner {
  position: absolute;
  top: 115px;
  left: 200px;
  z-index: 110;
}

.Download .download-button .dim {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 445px;
  height: 276px;
  opacity: .5;
  background-color: black;
}

.Download .download-button .logo {
  position: absolute;
  z-index: 20;
}

.Download .download-button .no-logo {
  position: absolute;
  z-index: 20;
  width: 4em;
  height: 3em;
  color: rgb( 207, 202, 200 );
  background-color: rgb( 73, 73, 75 );
  border: thin solid rgb( 207, 202, 200 );
  text-transform: uppercase;
  font-size: 12px;
}

.Download .download-button img.thumbnail {
  position: absolute;
  left: 0;
  border-style: solid;
  border-width: 5px;
  border-color: rgba( 255, 255, 255, 0 );
}

.Download .download-button:hover img.thumbnail {
  border-color: #f7c244;
}

.Download .thumbnail {
  max-width: 445px;
  max-height: 276px;
  z-index: 10;
}

@media (max-width: 992px) {
  .DownloadModal .modal-lg {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }

  .DownloadModal .download-header.download-original.col-0 {
    display: none;
  }

  .DownloadModal .download-header.download-original.col-12 {
    display: block;
  }
}

@media (max-width: 460px) {
  .Download .card-body {
    padding-left: 0;
    padding-right: 0;
  }

  .Download .container {
    padding-left: 0;
    padding-right: 0;
  }

  .Download .download-button {
    width: 286px;
    height: 177px;
  }

  .Download .thumbnail {
    max-width: 286px;
    max-height: 177px;
  }
}