
.Contact {
  color: #333333;
  font-family: 'Roboto', sans-serif;
  padding-left: 55px;
}

.Contact .tel,
.Contact .email {
  display: inline-block;
}

.Contact .name-block div {
  display: block;
}

.Contact a,
.Contact a:hover,
.Contact a:visited
{
  color: #007bff;
  text-decoration: none;
}

.Contact h3 {
  padding-top: 1em;
  font-size: 1.5rem;
}