.ItemList .card {
  margin-bottom: 15px;
  text-align: left;
}

@media (min-width: 576px){
  .ItemList .card {
    min-width: 43%;
    max-width: 43%;
  }
}

@media (min-width: 992px){
  .ItemList .card {
    min-width: 30%;
    max-width: 30%;
  }
}

.ItemList .card .card-footer {
  text-align: right;
}

.ItemList .card .card-footer .btn {
  margin-left: 0.5em;
}

.ItemList .card {
  background-color: rgba(255,255,255,0.30);
}

.ItemList .card .card-img-top {
  height: 192px;
  object-fit: contain;
}

.ItemList .card .card-body,
.ItemList .card .card-footer {
  background-color: black;
  color: white;
}

.ItemList .card .card-body {
  padding-bottom: 2px;
  font-weight: 400;
  color: white;
}

.ItemList .card-body .info {
  margin-bottom: 4px;
}

.ItemList .card-body .category-breaking {
  margin-left: -15px;
  font-size: 12px;
  font-weight: 700;
}

.ItemList .card-body .category {
  color: #FFc107;
}

.ItemList .card-body .pending {
  color: #FFc107;
}

.ItemList a.category-link {
  color: #FFc107;
}

.ItemList .card-body .breaking {
  padding-right: 0;
  padding-left: 0;
  text-align: right;
  color: #ca3a36
}

.ItemList .card-body .title {
  font-size: 16px;
  font-weight: bold;
}

.ItemList .card-body .description {
  font-size: 14px;
}

.ItemList .card-body .duration {
  font-size: 12px;
  position: absolute;
  bottom: 15px;
}

.ItemList .card .card-footer {
  padding-top: 2px;
}

.ItemList .IButton {
  color: black;
}

.NoItems {
  color: white;
  padding-top: 3em;
}

.modal-lg {
  max-width: 670px;
}

.modal-header {
  font-size: 12px;
  padding-bottom: 0;
  border-bottom: 0;
}