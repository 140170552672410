.Login {
  padding: 60px 0;
  color: white;
  text-align: center;
}

.Login img {
  height: 58px;
  margin-bottom: 50px;
}

@media (max-width: 768px){
  .Login img {
    display: none;
  }
}

.Login form {
  margin: 0 auto;
  max-width: 320px;
  text-align: left;
}

.Login .accountRequest {
  margin-top: 25px;
  font-family: 'Montserrat', sans-serif;
}

.Login .accountRequest a {
  color: white
}