.IButton {
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  line-height: normal;
  width: 2.2rem;
  height: 2.2rem;
  font-size: 0.95rem;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.66rem;
  margin: 1px;
}