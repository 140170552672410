.TagInput .tagInput {
  display: inline-block;
  margin-left: 2px;
  margin-top: 5px;
  margin-bottom: 3px;
  border-radius: 2px;
}

.TagInput .tag {
  margin-right: 5px;
  padding: 3px;
  background-color: #dddddd;
  border-radius: 6px;
}

.TagInput .tag .remove {
  padding-left: 2px;
}

.TagInput .tagInput .tagInputField,
.TagInput .tagInput .tagInputField:focus {
  margin: 0;
  border: 1px solid #eee;
}