.App {
}

h1 {
  padding-top: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

.App h1 {
  color: white;
  text-align: center;
}

.App .WaitTill {
  padding-top:25%;
  text-align: center;
}

.Topbar {
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 0;
  text-transform: uppercase;
  background-color: rgba(0,0,0,0.40)
}

.Topbar .username {
  text-transform: none;
}

.Topbar .dropdown-menu {
  font-size: 10px;
}

.Topbar .dropdown-toggle::after {
  vertical-align: .4em;
}

.AppNavbar {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  padding-top: 0;
  margin-bottom: 20px;
}

.AppNavbar .navbar-brand img {
  height: 29px;
}

.AppNavbar .navbar-brand {
  margin-right: .05rem;
}

.AppNavbar input,
.AppNavbar input:focus,
.AppNavbar input::placeholder
{
  color: #ffffff;
}

.AppNavbar .navbar-nav .nav-link {
  padding-right: 28px;
  padding-left: 28px;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 2px;
  margin-right: 2px;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .btn:hover{
  background-color: rgba(255,255,255,0.10);
  color: white;
}

.navbar-nav .form-control {
  border: 0;
  background-color: rgba(0,0,0,0);
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-nav .search {
  margin-right: 1em;
  padding-left: 28px;
}

.navbar-dark .navbar-nav .search input {
  border-radius: unset;
  box-shadow: 0px 0.5px 0px 0px #ffffff;
  padding-left: 0;
  margin-top: -2px;
  margin-bottom: -3px;
}


.navbar-nav .btn {
  border: 0;
}

.navbar-dark .btn {
  color: white;
}

nav .username::after {
  display: none;
}

.AppNavbar .form-control-sm {
  width: 90%;
}

.AppNavbar .btn {
  padding: 0;
  color: #cccccc;
  font-size: 90%;
}

.AppNavbar .btn:disabled{
  opacity: 0;
}

@media (max-width: 767px) {
  .AppNavbar .format-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .AppNavbar .format-mobile {
    display: none;
  }
}

.AppNavbar .format-desktop .format {
  font-size: 18px;
  margin-top: 8px;
  padding: 1px 0 0 0;
}

.AppNavbar .format {
  color: #F7C244 !important;
  font-weight: 700;
  outline: 0;
}

.AppNavbar .format-desktop .format:hover {
  background-color: #f7c24422;
}


.AppNavbar .format-uni::after {
  display: none;
}

.AppNavbar .format-multi::after {
  font-size: small;
}

.AppNavbar .format-desktop .format-multi::after {
  vertical-align: .7em;
}

.AppNavbar .format-mobile .format-multi::after {
  vertical-align: .5em;
}

.AppNavbar .dropdown-menu {
  border-color: rgba( 255, 255, 255, 0.25 );
  background-color: rgba( 0, 0, 0, 0.85 );
}

.AppNavbar .dropdown-item {
  color: white;
}

.AppNavbar .dropdown-item:focus,
.AppNavbar .dropdown-item:hover
{
  color: white;
  background-color: #333333;
}

.AppNavbar .dropdown-item.active {
  display: none;
}

.AppNavbar .dropdown-menu {
  font-size: 12px;
}

.AppNavbar .dropdown-toggle::after {
  vertical-align: .4em;
}


/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 90px; /* Margin bottom by footer height */
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px; /* Set the fixed height of the footer here */
  padding-top: 10px;
  line-height: 30px; /* Vertically center the text there */
  color: #cccccc;
  background-color: black;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}

.footer a,
.footer a:visited {
  color: #ffffff;
}

.footer img {
  height: 22px;
}