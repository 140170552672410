
.ReportTypeSelect .typeEntryLabel {
  font-weight: bold;
}

.ReportConfig > div {
  margin-top: 4px;
  margin-bottom: 4px;
}

.ReportConfig .label {
  padding-right: 0.5em;
}