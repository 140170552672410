
@media (min-width: 992px) {
  .XModal .modal-header .close {
    margin-right: -3.5rem;
    margin-top: -2rem;
    color: white;
    opacity: unset;
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    font-weight: bold;
  }
}

.XModal h1 {
  padding-top: 10px;
  margin-top: -1em;
  margin-bottom: 2px;
  padding-left: 1em;
  padding-right: 1em;
}

