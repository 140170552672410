.File {
  text-align: center;
}

.File .card {
  text-align: left;
  background-color: rgba(255,255,255,0);
  margin-left: 20%;
  margin-right: 20%;
  padding-bottom: 20px;
  border-top: 0;
}

.modal-content .File .card {
  margin-left: 0;
  margin-right: 0;
}

.File .card-header {
  background-color: rgba(255,255,255,0.20);
  height: 375px;
  text-align: center;
  padding: 0;
}

.File .card-header img {
  max-height: 375px;
}

.File .card-header video {
  max-height: 375px;
}

.File .card-header .pending {
  padding-top: 175px;
}

@media (max-width: 576px) {

  .File .card-header {
    height: auto;
  }

  .File .card-header img {
    max-height: 192px;
  }

  .File .card-header video {
    max-height: 192px;
  }

  .File .card-header .pending {
    padding-top: unset;
  }

}

.File .card-body {
  background-color: white;
  font-size: 14px;
  padding: 0;
}

.File .card-body .buttons {
  padding-top: 1em;
  text-align: right;
  padding-right: 1.25rem;
}

.File .card-body .buttons .IButton {
  margin-left: 0.5em;
  color: white;
}

.File .card-body .row {
  padding-top: 9px;
  padding-bottom: 9px;
  border-bottom: thin solid #dddddd;
}

.File .card-body .last-row {
  border-bottom: none;
}

.File .Tag {
  background-color: #eeeeee;
  border-radius: 3px;
  padding-left: 0.25em;
  padding-right: 0.25em;
  margin-right: 0.5em;
}

.File .description-container {
  padding-left: 0;
}

.File .description-container .row {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.File .copied-false,
.File .copied-true
{
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 576px) {
  .File .copied-false,
  .File .copied-true
  {
    display: unset;
    justify-content: unset;
  }
}

.File .copied-false {
  cursor: copy;
}

.File a.category-link {
  color: #212529;
}

.File .processing {
  text-align: center;
}

.File .processing .badge {
  font-size: 100%;
}

.FileLoading {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}