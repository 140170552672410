.Faq .qa-question-text {
  display: inline-block;
  font-weight: bold;
}

.Faq .qa-question-icon {
  display: inline-block;
  text-align: right;
}

.Faq .icon {
  transition-property: transform;
  transition-duration: 0.5s;
}

.Faq .icon-open {
  transform: rotate(180deg);
}

.Faq .IButton {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.TextCard.Faq {
  background-color: transparent;
}