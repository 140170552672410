.User .card {
  background-color: white;
}

.User input {
  margin-right: 1em;
  vertical-align: top;
}

.User .Input-children {
  display: inline-block;
}

.User .Input-children .Met-icon {
  padding-right: 0.25em;
}

.User .row {
  padding-bottom: 0.5em;
}

.User label {
  margin-bottom: 0.1rem;
}

.User .formatName {
  padding-left: 1.5em;
}

.User .format-group .input-group {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.User .format-group .input-group-prepend {
}

.User .format-group .input-group-text {
  width: 10em;
  text-transform: capitalize;
  display: inline-block;
  text-align: right;
}

.User .Expires .IButton {
  scale: 60%;
}

.User .Expires .IButton-times {
  margin-left: -10px;
  padding-left: 12px;
}