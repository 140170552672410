.btn-load-more {
  margin-top: 30px;
  margin-bottom: 60px;
  background-color: #ffffff20;
  border-color: darkgray;
  text-transform: uppercase;
}

.btn-load-more:hover {
  background-color: #5a6268cc;
}

.buttonContainer {
  min-width: 100%;
}

@media (min-width: 576px) {
  .buttonContainer {
    min-width: 100%;
  }
}