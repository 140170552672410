.UserSummary .datePicker {
  width: 6em;
}

.UserSummary .dateEntryLabel {
  padding-right: 0.5em;
}

.UserSummary .to {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.UserSummary .table {
  background-color: white;
}