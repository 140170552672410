
.Reports .ReportTypeSelect select {
  text-transform: capitalize;
}

.Reports select {
  padding: 4px;
}

.Reports select option[value=""],
.Reports select option:not([value]) {
  text-align: center;
  font-style: italic;
  color: grey;
}

.Reports .typeEntryLabel {
  padding-right: 0.5em;
}

.Reports .datePicker {
  width: 6em;
}

.Reports .dateEntryLabel {
  padding-right: 0.5em;
}

.Reports .to {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

