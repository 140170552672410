.Home .lander {
  text-align: center;
}

.Home .lander p {
  color: #999;
}

.Home h1 {
  padding-bottom: 10px;
}