
.Logo  {
  padding: 0;
}

.Logo .card {
  margin-left: 0;
  margin-right: 0;
  border-top: 0;
  padding-bottom: 20px;
}

.Logo .card-header {
  text-align: center;
}

.Logo .card-header img {
  max-width: 100%;
}

.Logo .download {
  padding-top: 1em;
  padding-bottom: 1em;
}

.Logo .sizing {
  padding-top: 1em;
  padding-bottom: 1em;
}