.UserList .card {
  background-color: white;
}

.UserList .card .WaitTill {
  padding-top: 100px;
  padding-bottom: 100px;
}

.UserList .card-body {
  padding-left: 0;
  padding-right: 0;
}

.UserList .row0 {
  background-color: #dddddd;
}