
.FileEdit form {
  padding-bottom: 15px;
}

.FileEdit textarea {
  height: 8em;
}

.FileEdit #desc {
  height: 6em;
}

.FileEdit .row {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.TagInput .selected {
  overflow-x: scroll;
}

.TagInput .selected::-webkit-scrollbar {
  display: none;
}

.FileEdit .react-datepicker-wrapper {
  width: 100%;
}

.FileEdit .react-datepicker-wrapper input {
  width: 100%;
}

.FileEdit .NowPicker select {
  width: 20%;
  display: inline-block;
}

.FileEdit .NowPicker .react-datepicker-wrapper {
  width: 80%;
  display: inline-block;
}

.FileEdit .FormatEntry .form-check .form-check-input {
  margin-top: 0.3rem;
}

.FileEdit .ThumbEntry {
  padding-left: 2em;
}

.FileEdit .ThumbEntry .Thumb {
  padding-left: 5px;
  padding-right: 5px;
}

.FileEdit .ThumbEntry .Thumb .img {
  width: 100%;
  height: 100%;
  background-color: #dddddd;
  object-fit: scale-down;
  border: 2px solid black;
  opacity: 0.75;
}

.FileEdit .ThumbEntry .Thumb.selected .img {
  opacity: 1;
  cursor: default;
}

.FileEdit .ThumbEntry .Thumb .img:hover {
  opacity: 1;
}

.FileEdit .ThumbEntry .Thumb .noThumbnail {
  padding-top: 19%;
  text-align: center;
  font-size: 0.75em;
}

.FileEdit .formats .row {
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.FileEdit .formats .input-group-prepend {
}

.FileEdit .formats .input-group-text {
  width: 10em;
  text-transform: capitalize;
  display: inline-block;
  text-align: right;
}

.FileEdit .formats .categories {
  flex-grow: 1;
  text-align: left;
  background-color: white;
}

.FileEdit .filename {
  font-weight: bold;
}

.FileEdit .update {
  margin-left: 2em;
  cursor: pointer;
}

.FileEdit .ButtonRow .col {
  display: flex;
  justify-content: space-between;
}

.FileEdit .ButtonRow .group {
  display: inline-block;
}